import { createApp } from 'vue';
import VConsole from 'vconsole';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

import App from './App.vue';
import router from './router';
import { MyComponent } from './components';
import { MyMbComponent } from './views/mobile/components';

declare global {
  interface Window {
    ng: string,
    proName: string,
    debug: boolean,
  }
}

// if (window.debug) {
//   new VConsole();
// }

const app = createApp(App).use(router);
app.use(ElementPlus);
for (const key in MyComponent) {
  if (Object.prototype.hasOwnProperty.call(MyComponent, key)) {
    const element = MyComponent[key];
    app.component(key, element);
  }
}
for (const key in MyMbComponent) {
  if (Object.prototype.hasOwnProperty.call(MyMbComponent, key)) {
    const element = MyMbComponent[key];
    app.component(key, element);
  }
}
app.mount('#app');
